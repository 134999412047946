<template lang="pug">
div(v-if='Inicio').pa-0

    v-container(fluid).pa-0 
      
        v-carousel(hide-delimiters='' cycle interval='4000' :show-arrows="true"  height="100%").d-none.d-md-block
            v-carousel-item(v-for='{imagen}, i in Inicio.portadas' :key='i' cover=''   )
                v-img(  height='470px'   :src='imagen'    ) 
        v-carousel(hide-delimiters='' cycle interval='4000' :show-arrows="true"  height="100%").d-md-none
            v-carousel-item(v-for='{imagen}, i in Inicio.portadas' :key='i' cover=''   )
                v-img(  :src='imagen'    ) 
    
        //- v-img(  :src='Inicio.portada' :aspect-ratio="1" ).d-md-none
    //- Urologias-PC
    .d-none.d-md-block
        v-row.justify-center.align-start(style="margin-top: 50px")
            v-row.justify-center.align-center.cajon
                
                v-card(  style="  border-radius: 15px; width: 60%").pa-3.elevation-1
                    v-row.justify-center.align-center
                        v-col(v-for='{icono, titulo,subtitulo,boton}, i in Contacto.urologias' :key='i' cols='3' )
                            

                            v-img(  :src='icono'  height='80' contain)
                            
                            h3(v-html='titulo').text-center.titulo
                            h2(v-html='subtitulo' ).text-center.subtitulo

                            .fila-boton

                                v-btn(@click='dirigirHacia("servicios")').elevation-0
                                    span(v-html='boton').font-italic       
                                            
                            
    //- Urologias-MOVIL
    .d-md-none
       
        v-row.justify-center.align-center.pa-5
            v-col(v-for='{icono, titulo,subtitulo,boton}, i in Contacto.urologias' :key='i' cols='4' )    
                v-img(  :src='icono'  height='70' contain)
                h3(v-html='titulo' style=" line-height: 0.5em;  color: #00AA7F; font-size:15px").text-center
                h2(v-html='subtitulo' style="color: #4C137B; line-height: 0em; font-size:20px").text-center
                .fila-boton
                    v-btn(x-small @click='dirigirHacia("servicios")').elevation-0
                        h3.white--text(v-html='boton' style="text-transform: capitalize; ").font-italic 
            
    
    //- CAJON DE MEDICOS ESPECIALISTAS-PC
    .d-none.d-md-block
        v-row.justify-center.align-start.griscajon.pa-9.mt-5
            v-col(cols=12 sm=12 md=12 lg=9)
                h2(v-html='Especialistas.titulo' ).text-center.titulo_verde
                h1(v-html='Especialistas.subtitulo' ).text-center.subtitulo_morado.
                v-row.justify-center.align-center.cajondoctores
                    v-col(v-for='{nombre, cpm, imagen}, i in Especialistas.medicos' :key='i' cols='4' v-if="Especialistas.medicos")
                        v-img(  :src='imagen'  height='200' contain).superponer_imagen  
                        v-row.justify-center.align-center.pb-12
                            v-card(  flat height='80').color_borde_verde
                                h3(v-html='nombre').especialistas_texto_morado.text-center
                                p(v-html='cpm').especialistas_texto_verde.text-center

    //- CAJON DE MEDICOS ESPECIALISTAS-MOVIL
    .d-md-none
        v-row.justify-center.align-center.pa-3.mt-10(style="background-color: #F4F4F3")
            v-col(cols=12 sm=12 md=12 lg=9)
                h2(v-html='Especialistas.titulo' ).text-center.titulo_verde
                h2(v-html='Especialistas.subtitulo' ).text-center.subtitulo_morado.
            
            v-carousel(hide-delimiters=''  height='100%').mt-4
                v-carousel-item(v-for='{nombre, cpm, imagen}, i in Especialistas.medicos' :key='i' cols='4' v-if="Especialistas.medicos")
                        v-img(  :src='imagen'  height='200' contain).superponer_imagen  
                        v-row.justify-center.align-center.pb-12
                            v-card(  flat height='80').color_borde_verde
                                h3(v-html='nombre').especialistas_texto_morado.text-center
                                p(v-html='cpm').especialistas_texto_verde.text-center
    //- IMAGEN TITULO - DE CITAS
    v-row.justify-center.align-center.pt-12
        v-img(  height='100' :src='Citas.titulo' contain ).d-none.d-md-block.mt-12

        v-img(  height='90' :src='Citas.titulo' contain ).d-md-none

    
    //- FORMULARIO
    .d-none.d-md-block
        v-row.justify-center.align-center.mt-12.mb-12(v-if='Citas')
            v-card().card
                v-row.justify-start.align-center 

                    v-col(cols=12 md=6)
                        v-img(  :src='Citas.imagen_citas'  contain).imagen_borde
                    v-col(cols=12 md=5)
                        v-form(v-model='validación').ml-10
                            v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]' :rules='[Reglas.requerido, Reglas.solo_letras]')
                            v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' :rules='[Reglas.requerido, Reglas.email_valido]')
                            v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]' :rules='[Reglas.requerido, Reglas.solo_números]')
                            v-select(v-model='medico'  :items='transformarObjetoaArray(Citas.medicos)' :item-text='item=>item.nombre' item-value='nombre' label='Doctores' persistent-hint='' )
                            v-select(v-if='medico.length>0' v-model='horario_de_medico' :items='rutina' label='Selecciona horario' )
                            .fila-boton.justify-center.align-items-center(style='display: flex')
                                v-btn(@click='enviar()' :loading='enviando_correo'  color='#00AA7F' style="border-radius: 10px").elevation-0
                                    h3.white--text(style="text-transform: capitalize; ").font-italic  Enviar
    //- FORMULARIO-movil
    .d-md-none
        v-row.justify-center.align-center.mt-12.mb-12(v-if='Citas')
            v-col(cols=10 )
                v-card(style="border-radius: 15px ")
                    v-col(cols=12)
                        v-img(  :src='Citas.imagen_citas'  contain style="border-radius: 15px ")
                    v-col(cols=12 )
                        v-form(v-model='validación')
                            v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]' :rules='[Reglas.requerido, Reglas.solo_letras]')
                            v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' :rules='[Reglas.requerido, Reglas.email_valido]')
                            v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]' :rules='[Reglas.requerido, Reglas.solo_números]')
                            v-select(v-model='medico'  :items='Citas.medicos' :item-text='item=>item.nombre' item-value='nombre' label='Doctores' persistent-hint='' single-line='')
                    
                            v-select(v-if='medico.length>0' v-model='horario_de_medico' :items='rutina' label='Selecciona horario')
                            .fila-boton.justify-center.align-items-center.centrar(style='display: flex')
                                v-btn(@click='enviar()' :loading='enviando_correo'  color='#00AA7F' style="border-radius: 10px")
                                    h3.white--text(style="text-transform: capitalize; ").font-italic  Enviar
    //- SERVICIOS - Pc
    .d-none.d-md-block
        v-row.justify-center.align-center
            v-img(  height='100%' :src='Inicio.imagen_servicios' contain )
                v-container(fluid)
                    h3(v-html='Servicios.titulo_1').text-center.text_verde
                    h2(v-html='Servicios.titulo_2').text-center.white--text.titulo_principal
                    v-row.justify-center.align-start.my-12.cajon_servicios
                        v-col(v-for='{texto,imagen}, i in Servicios.servicios' :key='i' cols='3')
                            v-img(  :src='imagen'  height='80' contain)
                            p(v-html='texto').text-center.white--text.alineamiento_servicios.mt-3
                        .fila-boton
                            v-btn(rel="stylesheet" href="/servicios" style="border-radius: 10px").elevation-0
                                h3.white--text(style="text-transform: capitalize; ").font-italic   Ver más
    
    //- SERVICIOS - MOVIL
    .d-md-none
        v-row.justify-center.align-center(fluid style="background-color: #490383")
            v-container(fluid)
                h3(v-html='Servicios.titulo_1' style="color: #29D895; line-height: 0.6em; font-style: italic").text-center.mt-10
                h3(v-html='Servicios.titulo_2' style="line-height: 0.6em ").text-center.white--text
                v-row.justify-center.align-start.pa-4.mt-5
                    v-carousel(hide-delimiters=''  height='200').mt-4
                        v-carousel-item(v-for='{texto,imagen}, i in Servicios.servicios' :key='i' cover='')
                            v-img(  :src='imagen'  height='100' contain).superponer_imagen  
                            v-row.justify-center.align-center.pb-12
                                
                                    p(v-html='texto' style="line-height: 0.6em; font-size: 15px").white--text.text-center.mt-9
                
                .fila-boton.mb-4
                    v-btn(rel="stylesheet" href="/servicios" style="border-radius: 10px").elevation-0
                        h3.white--text(style="text-transform: capitalize; ").font-italic  Ver más
    

    //- PCIENTES
    .d-none.d-md-block.pa-5
      
        v-col.justify-center.align-center   
            h2(v-html='Inicio.titulo_p1' style="color: #490383; ").text-center.font-italic.font-weight-bold
            h2(v-html='Inicio.titulo_p2'  style="color: #00AA7F ; line-height: 0em").text-center.font-italic.font-weight-bold
        v-row.justify-center.align-center   
            
            v-col(cols=12 md=10)
                v-carousel(hide-delimiters='' cycle interval='4000' height='300' width='300').mt-4
                    v-carousel-item(v-for='{imagen}, i in Inicio.pacientes' :key='i' cover='')
                        v-img(  :src='imagen'  height='100%' contain) 
                   
</template>
<script>
    import { mapActions, mapState } from 'vuex'
        export default {
            data: ()=>({
                información: {},
                enviando_correo: false,
                validación: true,
                medico: "",
                horario_de_medico: ""
            }),
            methods: {
                dirigirHacia(ruta){
                    this.$router.push({name: ruta})
                    setTimeout(() => {
                        this.$vuetify.goTo("#serviciosContainer")    
                    }, 300);
                },
                transformarObjetoaArray(objeto) {
                    if(typeof objeto === 'object') return Object.keys(objeto).map(key=>objeto[key])
                    else if(Array.isArray(objeto)) return objeto
                    else return []
                },
                ...mapActions({
                    enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
                    activar_snackbar: 'Ayudas/activar_snackbar',
                }),
                enviar(){
                    const titulo = 'Información del contacto'
                    const asunto = `Nuevo contacto`
                    let cuerpo = `
                    <table>`
                    Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
                    cuerpo += `</table>`
                    if(this.medico) cuerpo = cuerpo+= `<tr style="padding-right: 2px"><td>Médico: </td><td>${this.medico}</td></tr><br>`
                    if(this.horario_de_medico) cuerpo = cuerpo+= `<tr><td>Horario: </td><td>${this.horario_de_medico}</td></tr>`
                    const configuracion_de_correo = {
                        auth: {
                            user: 'admin@uroavanza.com',
                            pass: 'Uroavanza_123'
                        },
                        port: 465,
                        secure: true,
                        host: 'smtp.zoho.com' 
                    }
                    const correo_electronico = 'informes@uroavanza.com'
                    this.enviando_correo = true
                    this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
                    .then( () => {
                        this.activar_snackbar('Gracias. Nos pondremos en contacto.').notificacion
                        this.enviando_correo = false
                        this.información  = {}
                    })
                },
            },
            computed: {
                ...mapState({
                    Reglas: ({Ayudas})=>Ayudas.reglas,
                }),
                rutina(){{
                return this.Citas.medicos.find(item=>item.nombre === this.medico).horario || []
            }}
            }
        }
</script>


<style lang="sass" scoped>
.centrar
    display: flex
    justify-content: center
    align-items: center
.alineamiento_servicios
    line-height: 0.6em

.text_verde
    margin-top: 3em
    color: #29D895
    
    line-height: 0.6em
    margin-left:45%
.titulo_principal
    line-height: 0.6em
    margin-left:45%
.cajon_servicios
    margin-left:40%
    margin-right:4em
.imagen_borde
    border-radius: 15px 0 0 15px 
    display: flex
    flex-direction: column
 
.card
    width: 60%
  
    border-radius: 15px


.griscajon
  background-color: #F4F4F3
  
.cajon
  position: absolute
  margin-top: -6%
  z-index: 1
  width: 100em

  

.titulo
   
    line-height: 1em
    font-size: 14px
  
.subtitulo
    color: #490383
    line-height: 1%
    
 

.fila-boton
    padding-top: 10px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 15px
    .v-btn  
        background-color: #00AA7F
        color: white
.nombredoctor
    color: #490383

    font-size: 20px
.cargo
    color: #00AA7F
    line-height: 2%

.firma  
    right: 40%
    margin-top: -3%

.red-social
    img
        width: 23px
        cursor: pointer

.griscajon
    background-color: #F4F4F3
    height: 400px
    margin-bottom: 8%
.superponer_imagen  
    z-index: 2
.cajondoctores
    position: relative
    padding-top: 50px

    margin-left: 3%
    margin-right: 3%

.color_borde_verde
    border: 3px solid #00AA7F !important
    border-radius: 15px !important
    position:absolute
    z-index: 1
    top: -10px
    width: 300px


.color_borde_morado
    border: 3px solid #4C137B !important
    border-radius: 50px
    width: 70%
    margin-top: 3em

.titulo_verde
    color: #00AA7F
    line-height: 1em
    margin-top: 10%
.subtitulo_morado
    color: #4C137B
    line-height: 0.5em
.especialistas_texto_verde
    color: #00AA7F
    line-height: 0%
.especialistas_texto_morado
    color: #4C137B
    line-height: 0.5em
    margin-top: 30px
.lineamedia
   
    position: absolute
    height: 100px
    width:3px
    margin-top: -10%
    background-color: red !important

.notificacion
    background-color: #4C137B
   
</style>